import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.13_@babel+core@7.24.9_@playwright+test@1.47.1_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.13_@babel+core@7.24.9_@playwright+test@1.47.1_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/welcome/add-places.jpg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/welcome/categories.jpg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/welcome/italy-map.jpg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/welcome/new-york-map.jpg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/welcome/one-vanderbilt.jpg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/welcome/thailand-map.jpg");
;
import(/* webpackMode: "eager", webpackExports: ["WelcomePageCategorySelect"] */ "/vercel/path0/src/app/[lang]/welcome-page-category-select.tsx");
